<template>
  <!-- <html>
    <head>
      <title>Invoice MetalXperts LLP</title>
    </head>
    <body> -->
  <div class="body">
    <div
      class="row d-flex flex-row m-0 mt-14 g-12 justify-content-center align-items-center"
    >
      <v-card-actions class="d-flex justify-content-end" v-if="!listVisible">
        <v-btn
          class="btn btn-dark me-3 btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_add_planning_indent"
          @click="showList"
          >Create Planning Indent
        </v-btn>
        <v-btn class="btn btn-success btn-sm" @click="showList"
          >Show List</v-btn
        >
      </v-card-actions>
      <v-card-actions class="d-flex justify-content-end" v-if="listVisible">
        <v-btn class="btn btn-danger btn-sm text-nowrap" @click="hideList"
          >Hide List</v-btn
        >
      </v-card-actions>
    </div>
    <div class="wrapper">
      <div class="invoice_wrapper border border-3 border-dark">
        <div class="header row mb-20">
          <div class="d-flex justify-content-between mb-20">
            <div class="d-flex align-items-center pl-20">
              <div class="ms-3">
                <p class="fs-3 fw-bolder">MetalXperts (India)LLP</p>
                <div class="fs-6 pt-1 lh-3">
                  <p>
                    Survey No. 799 At. Chhatral,Tal.Kalol,Chhatral(CT)-382729
                    Dist:Gandhinagar,<br />gujarat - India
                  </p>
                  <p>
                    <span class="fw-bolder">Contact No:</span
                    ><span class="fw-bolder">Email:</span>info@metalxperts.com
                    <span class="fw-bolder">Website</span
                    >www.metalxperts.com<br />
                    <span class="fw-bolder">GSTIN:</span>24ABFFM7697B1Z4
                    <span class="fw-bolder">PAN:</span>ABFFM7697B
                  </p>
                </div>
              </div>
            </div>
            <div class="text-start">
              <table
                class="border-start border-dark border-2"
                style="width: 100%"
              >
                <tr class="bottom">
                  <th colspan="2" class="invoice">Purchase Request</th>
                </tr>
                <tr class="bottom">
                  <td>PR NO</td>
                  <td><span>: </span></td>
                </tr>
                <tr class="bottom">
                  <td>PR Date</td>
                  <td><span>: </span></td>
                </tr>
                <tr class="bottom">
                  <td>Based On</td>
                  <td><span>: Manual</span></td>
                </tr>
                <tr class="bottom">
                  <td>Type</td>
                  <td><span>: Saleable</span></td>
                </tr>
              </table>
            </div>
          </div>
          <!-- <hr class="line" /> -->
          <!-- <div class="d-flex justify-content-between p-0 mt-0">
          <div class="bill_sec">
            <p class="p-10">
              We are pleased to confirm our requirement of the following
              product:
            </p>
          </div>
        </div> -->
        </div>
        <div class="pt-4">
          <el-table
            :data="myData"
            border
            :default-sort="{ prop: 'srno', order: 'descending' }"
            style="width: 100%"
          >
            <el-table-column
              prop="srno"
              label="S.R. No."
              width="100"
              sortable
            />
            <el-table-column prop="sku" label="SKU" width="150" sortable />
            <el-table-column
              prop="product_description"
              label="Product Description"
              width="350"
              sortable
            />
            <el-table-column prop="make" width="250" label="Make" sortable />
            <el-table-column
              prop="remarks"
              width="180"
              label="Remarks"
              sortable
            />
            <el-table-column prop="uom" width="150" label="UOM" sortable />

            <el-table-column
              prop="quantity"
              width="150"
              label="Quantity"
              sortable
            />
            <el-table-column prop="rate" width="150" label="Rate" sortable />
            <el-table-column
              prop="ammount"
              width="150"
              label="Ammount"
              sortable
            />
          </el-table>
          <el-table-footer
            v-show="false"
            :data="summery"
            :ref="footerTableRef"
          ></el-table-footer>
        </div>
        <!-- <div class="row pt-0">
          <table class="border border-dark border-2 mt-4">
            <tr class="mt-20 border border-dark border-2" height="50px">
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 5%"
              >
                Sr.No
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 10%"
              >
                SKU
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 30%"
              >
                Product Description
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 20%"
              >
                Make
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 5%"
              >
                Remarks
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 5%"
              >
                Quantity
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 7.33%"
              >
                UOM
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 9.33%"
              >
                Rate
              </th>
              <th
                class="border-end border-dark border-2 text-centre"
                style="width: 8.33%"
              >
                Amount
              </th>
            </tr>
            <tr class="border border-dark border-2">
              <td class="border1 text-centre">1</td>
              <td class="border text-centre"></td>
              <td class="border text-centre"></td>
              <td class="border text-centre"></td>
              <td class="border text-centre"></td>
              <td class="border text-centre"></td>
              <td class="border text-centre"></td>
              <td class="border text-centre"></td>
              <td class="border1 text-centre"></td>
            </tr>
            <tr>
              <td class="border1 text_right" colspan="5">Total</td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border"></td>
              <td class="border1"></td>
            </tr>
            <tr>
              <td class="border1 text_right" colspan="7"></td>
              <td class="border">Round Up</td>
              <td class="border1"></td>
            </tr>
            <tr>
              <td class="border1 text_right" colspan="7"></td>
              <td class="border">Grand Total</td>
              <td class="border1"></td>
            </tr>
            <tr height="70px">
              <td class="border1 table_text_left" colspan="9">Remarks:</td>
            </tr>
            <tr class="" height="300px">
              <td class="border2" colspan="9"></td>
            </tr>
          </table>
          <table>
            <tr class="" height="100px">
              <td
                class="bottom table_text_align"
                colspan="3"
                rowspan="2"
                width="33.3333%"
              >
                Prepared By
              </td>
              <td
                class="bottom left table_text_align"
                colspan="3"
                rowspan="2"
                width="33.3333%"
              >
                Checked By
              </td>
              <td class="left table_text_right" colspan="3">
                For MetalXperts(india)LLP
              </td>
            </tr>
            <tr class="">
              <td
                class="bottom left table_text_right"
                colspan="3"
                width="33.3333%"
              >
                Authorised Signatory
              </td>
            </tr>
          </table>
        </div> -->
        <div class="bill_total_wrap">
          <div class="bill_sec p-10">
            <span>
              <p class="">
                E & O.E <span class="alignright">Page No 1 of 1</span>
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </body>
  </html> -->
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { ElTable } from "element-plus";

export default defineComponent({
  name: "invoice-html",
  setup() {
    // const footerTableRef = ref<InstanceType<typeof ElTable>>();
    // const footerTableRef = ref(null);
    const footerTableRef = ref(null);
    const table = ref(null);

    // footerTableRef.value().init(table.value());

    const myData = ref([
      {
        srno: 1,
        sku: 2,
        make: "JSW Steel",
        remarks: "test",
        quantity: 4,
        uom: "Mt",
        rate: 2,
        ammount: 2000,
      },
      {
        srno: 2,
        sku: 2,
        make: "JSW Steel",
        remarks: "test",
        quantity: 4,
        uom: "Mt",
        rate: 2,
        ammount: 2000,
      },
      {
        srno: 3,
        sku: 2,
        make: "JSW Steel",
        remarks: "test",
        quantity: 4,
        uom: "Mt",
        rate: 2,
        ammount: 2000,
      },
    ]);

    const footerData = ref([
      {
        label: "Total",
        data: {
          quantity: 13414,
        },
      },
    ]);

    const summery = () => {
      return [footerData];
    };
    const showList = () => {
      console.log("hi");
    };
    return {
      myData,
      footerData,
      footerTableRef,
      showList,
      summery,
    };
  },
});
</script>
<style type="text/css" scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;400;900&display=swap");

:root {
  --primary: #0000ff;
  --secondary: #3d3d3d;
  --white: #fff;
  --Black: #000;
}

* {
  margin: 0;
  padding: 0;
  /*box-sizing: border-box;
  font-family: 'Lato', sans-serif;*/
}

body {
  background: var(--secondary);
  padding: 50px;
  color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.bold {
  font-weight: 900;
}

.light {
  font-weight: 100;
}

.wrapper {
  background: var(--white);
  padding: 30px;
}

.invoice_wrapper {
  border: 3px solid var(--Black);
  width: 100%;
  max-width: 100%;
}

.invoice_wrapper .header .logo_invoice_wrap,
.invoice_wrapper .header .bill_total_wrap {
  display: flex;
  justify-content: space-between;
  padding: 0px;
}

.invoice_wrapper .header .logo_sec {
  display: flex;
  align-items: center;
}

.invoice_wrapper .header .logo_sec .title_wrap {
  margin-left: 5px;
}

.invoice_wrapper .header .logo_sec .title_wrap .title {
  /*text-transform: uppercase;*/
  font-size: 24px;
  color: var(--Black);
}

.invoice_wrapper .header .logo_sec .title_wrap .sub_title {
  font-size: 13px;
  padding-top: 2px;
  line-height: 1.3;
}

.invoice_wrapper .header .invoice_sec,
.invoice_wrapper .header .bill_total_wrap .total_wrap {
  text-align: left;
  width: 33%;
}

.invoice_wrapper .header .invoice_sec .invoice {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin: 20px;
}

.invoice_wrapper .header .invoice_sec .invoice_no,
.invoice_wrapper .header .invoice_sec .date {
  display: flex;
  width: 100%;
}

.invoice_wrapper .header .invoice_sec .invoice_no span:first-child,
.invoice_wrapper .header .invoice_sec .date span:first-child {
  width: 100px;
  text-align: left;
}

.invoice_wrapper .header .invoice_sec .invoice_no span:last-child,
.invoice_wrapper .header .invoice_sec .date span:last-child {
  width: calc(100% - 70px);
}

.invoice_wrapper .header .bill_total_wrap .total_wrap .price,
.invoice_wrapper .header .bill_total_wrap .bill_sec .name {
  color: var(--primary);
  font-size: 20px;
}

.invoice_wrapper .body .main_table .table_header {
  background: var(--primary);
}

.invoice_wrapper .body .main_table .table_header .row {
  color: var(--white);
  font-size: 18px;
  border-bottom: 0px;
}

.invoice_wrapper .body .main_table .row {
  display: flex;
  border-bottom: 1px solid var(--secondary);
}

.invoice_wrapper .body .main_table .row .col {
  padding: 10px;
}
.invoice_wrapper .body .main_table .row .col_no {
  width: 5%;
}
.invoice_wrapper .body .main_table .row .col_des {
  width: 45%;
}
.invoice_wrapper .body .main_table .row .col_price {
  width: 20%;
  text-align: center;
}
.invoice_wrapper .body .main_table .row .col_qty {
  width: 10%;
  text-align: center;
}
.invoice_wrapper .body .main_table .row .col_total {
  width: 20%;
  text-align: right;
}

.invoice_wrapper .body .paymethod_grandtotal_wrap {
  display: flex;
  justify-content: space-between;
  padding: 5px 0 30px;
  align-items: flex-end;
}

.invoice_wrapper .body .paymethod_grandtotal_wrap .paymethod_sec {
  padding-left: 30px;
}

.invoice_wrapper .body .paymethod_grandtotal_wrap .grandtotal_sec {
  width: 30%;
}

.invoice_wrapper .body .paymethod_grandtotal_wrap .grandtotal_sec p {
  display: flex;
  width: 100%;
  padding-bottom: 5px;
}

.invoice_wrapper .body .paymethod_grandtotal_wrap .grandtotal_sec p span {
  padding: 0 10px;
}

.invoice_wrapper
  .body
  .paymethod_grandtotal_wrap
  .grandtotal_sec
  p
  span:first-child {
  width: 60%;
}

.invoice_wrapper
  .body
  .paymethod_grandtotal_wrap
  .grandtotal_sec
  p
  span:last-child {
  width: 40%;
  text-align: right;
}

.invoice_wrapper
  .body
  .paymethod_grandtotal_wrap
  .grandtotal_sec
  p:last-child
  span {
  background: var(--primary);
  padding: 10px;
  color: #fff;
}

.invoice_wrapper .footer {
  padding: 30px;
}

.invoice_wrapper .footer > p {
  color: var(--primary);
  text-decoration: underline;
  font-size: 18px;
  padding-bottom: 5px;
}

.invoice_wrapper .footer .terms .tc {
  font-size: 16px;
}
.bold {
  font-weight: bold;
}

.pb-4 {
  padding-bottom: 6px;
}
.pl-5 {
  padding-left: 5px;
}
.vl {
  border-left: 0.5px solid black;
  height: 150px;
}
.p-10 {
  padding: 10px;
}
.pl-20 {
  padding-left: 20px;
}
.f-14 {
  font-size: 14px;
}

table {
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  text-align: left;
  padding: 4px;
}
.bottom {
  border-bottom: 1px solid black;
}
.left {
  border-left: 1px solid black;
}
.border1 {
  border-top: 1px solid black;
}
.border {
  border: 1px solid black;
}
.border2 {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.text_right {
  text-align: right;
}
.table_text_align {
  text-align: center;
  vertical-align: text-top;
}
.table_text_right {
  text-align: right;
  vertical-align: text-top;
}
.table_text_left {
  text-align: left;
  vertical-align: text-top;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}
.text-centre {
  text-align: center;
}
.line {
  border-top: 1px solid black;
}
</style>
